import { serialize } from "object-to-formdata";

let crumb = window.crumb || window.Crumb || {};

// https://stackoverflow.com/a/2117523/1177228
function generateId() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function sendRequest(url, data) {
  if (typeof(window.navigator.sendBeacon) !== 'undefined')
    window.navigator.sendBeacon(url, serialize(data));
  else {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(data));
  }
}

crumb.drop = function () {
  let event = {
    name: '$view',
    properties: {
      url: window.location.href,
      title: document.title,
      page: window.location.pathname,
    },
    time: (new Date()).getTime() / 1000.0,
    id: generateId(),
    js: true
  };
  let data = {
    events_json: JSON.stringify([event]),
    landing_page: window.location.href
  };

  if (document.referrer.length > 0) {
    data.referrer = document.referrer;
  }

  sendRequest('/drop', data)

  return true;
};

export default crumb;
