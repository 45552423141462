/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import crumb from 'packs/crumb';
import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'packs/cookie_consent';
import { Alert, Button, Carousel, Collapse, Dropdown, Modal, Tab } from 'bootstrap';

Rails.start();
ActiveStorage.start();

document.addEventListener("DOMContentLoaded", function() {
  crumb.drop();
});
